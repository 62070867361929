<template>
  <ContentWrapper>
    <div class="d-flex justify-content-end">
      <!--      <el-date-picker-->
      <!--        size="small"-->
      <!--        v-model="filters.dateRange"-->
      <!--        type="datetimerange"-->
      <!--        :picker-options="pickerOptions"-->
      <!--        start-placeholder="Start date"-->
      <!--        end-placeholder="End date">-->
      <!--      </el-date-picker>-->
      <!--      <el-select v-model="filters.isFinished"-->
      <!--                 clearable-->
      <!--                 :placeholder="$t('system.status')"-->
      <!--                 @change="fetchData">-->
      <!--        <el-option :value="false" :label="$t('system.not_finished')"/>-->
      <!--        <el-option :value="true" :label="$t('system.finished')"/>-->
      <!--      </el-select>-->
      <el-button
          @click="fetchData"
          size="mini"
          type="info"
      >
        <font-awesome-icon icon="sync-alt" />
      </el-button>
    </div>
    <hr>
    <DataTable
        :headers="headers"
        :imagesCollections="{usersAvatars}"
        :loading="loading"
        :model="timeLoggers"
        :params.sync="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
        idPath="entityId"
    />
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
import DataTable from '../../components/Table/DataTable.vue';
import {diff, secondsToHHmmss} from '../../helpers/time';
import issue from '../../mixin/issue';

export default {
  name: 'TimeLoggerIndex',
  mixins: [issue],
  components: {DataTable, ContentWrapper},
  data() {
    return {
      // filters: {
      //   userId: 2,
      //   isFinished: null
      // },
      loading: true,
      timeLoggers: [],
      headers: [
        {
          key: 'entityId',
          label: 'system.entity_id',
          type: 'string',
        },
        {
          key: 'entityName',
          label: 'system.entity_name',
          type: 'string',
        },
        {
          key: 'username',
          label: 'system.username',
          type: 'stringWithImage',
          imgRef: {
            collection: 'usersAvatars',
            identifier: 'userAvatarId',
          },
        },
        {
          key: 'timeSpent',
          label: 'system.time_spent',
          type: 'timeLogger',
        },
      ],
      params: {
        column: 'timeSpent',
        direction: 'asc',
        per_page: 10,
        page: 1,
        total: 0,
        per_page_sizes: [10, 30, 50, 100],
      },
      usersAvatars: [],
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    // getFilters() {
    //   const filters = {};
    //   Object.keys(this.filters)
    //     .filter(key => this.filters[key] !== null)
    //     .forEach(key => {
    //       filters[camelCaseToUnderscoreCase(key)] = this.filters[key]
    //     })
    //   return filters
    // },
    fetchData() {
      this.loading = true;
      axios.get('time-logger', {
        params: this.params,
      }).then(({data}) => {
        this.params.total = data.total;
        let avatarsIds = [];
        this.timeLoggers = data.data.map(item => {
          if (item.userAvatar) {
            avatarsIds.push(item.userAvatar);
          }
          return {
            entityId: item.entityId,
            userAvatarId: item.userAvatar,
            username: `${item.userFirstName} ${item.userLastName}`,
            timeSpent: {
              currentState: item.currentState,
              time: secondsToHHmmss(item.timeSpent ? item.timeSpent : 0 + diff(item.startedAt)),
            },
            entityName: item.entityName,
          };
        });
        avatarsIds = [...new Set(avatarsIds)];
        this.fetchAvatars(avatarsIds);
      }).finally(() => {
        this.loading = false;
      });
    },
    fetchAvatars(ids) {
      const idsToFetch = ids.filter(x => !this.usersAvatars.map(item => item.id).includes(x));
      const idsToRemove = this.usersAvatars.map(item => item.id).filter(x => !ids.includes(x));
      this.usersAvatars = this.usersAvatars.filter(item => !idsToRemove.includes(item.id));
      return idsToFetch.map(id => {
        return axios.get(`avatar/${id}`, {
          responseType: 'blob',
          params: {type: 'mime_type'},
        }).then(response => {
          if (response.data && response.data.size) {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = () => {
              const candidateIdx = this.usersAvatars.findIndex(item => item.id === id);
              if (candidateIdx !== -1) {
                this.usersAvatars[candidateIdx].img = reader.result;
              } else {
                this.usersAvatars.push({
                  id, img: reader.result,
                });
              }
            };
          } else {
            this.usersAvatars.push({id});
          }
        });
      });
    },
    onClickRow(id) {
      this.openIssue(id, this.timeLoggers.map(item => item.entityId));
    },
  },
};
</script>
<style>

</style>
